<template>
  <button
    class="button button--twitch button--large"
    :disabled="disabled"
    :style="disabled ? 'opacity: 0.5;' : ''"
    @click="showAuthDialog"
  >
    <Icon class="mr-2" icon="twitch" width="20px" color="white" />

    <span>
      {{ text }}
    </span>
  </button>
</template>

<script>
import { Icon } from "cavea-design-system";

export default {
  name: "Twitch",

  components: {
    Icon,
  },

  props: {
    showName: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: "Sign in with Twitch",
    },
  },

  methods: {
    showAuthDialog() {
      const win = window.open(`${process.env.VUE_APP_NETWRK_AUTH}/auth/twitch`, "_self");
      win.focus();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/colors";
@import "../../styles/index";

* {
  color: white;
}

.button {
  display: flex;
  cursor: pointer;
  height: 2.5rem;
  align-items: center;
  font-weight: 600;
  flex-grow: 0;
  text-align: left;
  outline: 0;
  background: transparent;
  white-space: nowrap;
  font-family: $primary-font-stack;
  border: 0;
  color: color(blue, light);
  font-size: 1rem;
  transition: var(--transition-in);
  background-color: #a970ff;
  padding: 1rem 2rem;
  border-radius: 5px;

  &--disabled {
    opacity: 0.5;
  }
  &:hover {
    color: color(white, base);
  }

  &:active {
    top: 1px;
  }

  .icon:not(:last-child) {
    margin-right: 0.5rem;
  }
}
</style>
